





















































































import Vue from 'vue'
import TabSwitcher from '../../../components/TabSwitcher.vue'
import VSelect from '../../../components/BaseComponents/VSelect/VSelect.vue'
import Modal from '../../Modal.vue'
import BaseCirclePreloader from '../../../components/BaseCirclePreloader.vue'
import AdAccountDropdown from '../../../components/AdAccountDropdown.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as AdAccountConstants from '@/constants/AdAccount'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import { messages } from '@/i18n/messages'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
  name: 'ApplyRuleFromStrategyModal',
  components: {
    Modal,
    TabSwitcher,
    VSelect,
    BaseCirclePreloader,
    AdAccountDropdown,
    BaseInputVuelidate
  },
  props: {
    strategyId: {
      required: true
    }
  },
  data () {
    return {
      new_name: '',
      showError: false,
      selected: '',
      adAccountId: null,
      loading: false,
      messageResult: '',
      activeTab: {
        id: 0,
        name: 'new_folder',
        title: 'New folder',
        description: 'Configure your profile settings and manage notifications.'
      },
      activeTabName: 'new_folder',
      menu: [
        {
          id: 0,
          name: 'new_folder',
          title: 'New folder',
          description: ''
        },
        {
          id: 1,
          name: 'existing_folder',
          title: 'Existing folder',
          description: 'Set up your billing information and control your subscription.'
        }
      ]
    }
  },
  mounted () {
    this.loadAccounts({ search: { target: 'ads_management' } }).then(() => {
      this.adAccountId = this.availableAdAccounts[0]?.id
    })
  },
  validations: {
    new_name: {
      required
    }
  },
  i18n: {
    messages
  },
  methods: {
    ...mapMutations('rulesFolders', ['setFolderStateProp', 'openFolder']),
    ...mapActions('rulesFolders', ['creatingFolder']),
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('strategy', ['applyStrategies', 'getApplyingJobResult']),
    switchTab (acc) {
      this.activeTabName = acc.name
      this.activeTab = this.menu[acc.index]
    },
    repeatRequest (jobId, status) {
      if (status === 'CREATED' || status === 'PROCESS') {
        setTimeout(() => {
          this.getApplyingJobResult({ id: jobId })
            .then(res => {
              if (res.status === 'CREATED' || res.status === 'PROCESS') {
                this.repeatRequest(jobId, res.status)
              } else {
                this.loading = false
                if (res.error_message) {
                  this.messageResult = { show: true, type: 'danger', message: res.error_message }
                } else {
                  this.messageResult = { show: true, type: 'success', message: 'SUCCESS' }
                  this.$modal.hide('apply-rule-from-strategy')
                  this.$router.push({
                    name: 'AutomatedRules',
                    params: {
                      service: this.service,
                      loadFolderCallback: () => {
                        this.openFolder(this.creatingData.folder_id)
                      }
                    }
                  })
                }
              }
            })
        }, 3000)
      }
    },
    apply () {
      if (this.activeTab.name === 'new_folder') {
        this.creatingData = {
          ad_account_id: this.adAccountId,
          folder_id: null,
          folder_name: this.new_name
        }
      }
      if (this.activeTab.name === 'existing_folder') {
        this.creatingData = {
          ad_account_id: this.adAccountId,
          folder_id: this.selected.id
        }
      }
      // if (this.creatingWay === 'Do not create folder') {
      //   this.creatingData = {
      //     ad_account_id: this.newRule.ad_account_id ? this.newRule.ad_account_id : this.adAccount.id,
      //     folder_id: null
      //   }
      // }
      this.applyStrategies({
        strategyId: this.strategyId,
        data: this.creatingData
      })
        .then((res) => {
          this.loading = true
          this.repeatRequest(res, 'CREATED')
        })
    }
  },
  computed: {
    ...mapState('strategy', ['strategyById']),
    ...mapGetters('rulesFolders', ['getAllFolders', 'getError']),
    ...mapGetters('adAccounts', ['getAllAccounts']),
    ...mapGetters('adService', { service: 'getService' }),
    availableAdAccounts () {
      return this.$store.state.adAccounts.data.filter(a => a.status === AdAccountConstants.STATUS_IMPORTED)
    }
  }
})
