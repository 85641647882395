var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"title":"Use this strategy","name":'apply-rule-from-strategy',"classes":'ApplyRuleModel',"width":"540","max-width":NaN,"height":"auto"},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.loading)?_c('BaseCirclePreloader'):_vm._e(),_c('div',{staticClass:"mt-1 mb-1"},[_c('AdAccountDropdown',{style:({minWidth: '200px'}),attrs:{"itemKey":'name',"loading":_vm.$store.getters['adAccounts/getLoader'],"options":_vm.availableAdAccounts,"option":_vm.availableAdAccounts[0]},on:{"choose":function($event){_vm.adAccountId = $event.id}}})],1),_c('TabSwitcher',{attrs:{"tab-menu":_vm.menu,"activeTab":_vm.activeTab},on:{"switchTab":function($event){return _vm.switchTab($event)}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticStyle:{"transition":"1s","height":"auto"}},[(_vm.activeTabName === 'new_folder')?_c('div',[_c('BaseInputVuelidate',{attrs:{"title":"Name","placeholder":"Folder name","min-width":'200px',"v":_vm.$v.new_name},on:{"changeValue":function($event){_vm.new_name = $event.value}}},[(!_vm.$v.new_name.required && _vm.$v.new_name.$dirty)?_c('span',{staticClass:"error p-2"},[_vm._v(_vm._s(_vm.$t('error.empty')))]):_vm._e()])],1):_vm._e(),(_vm.activeTabName === 'existing_folder')?_c('div',[_c('p',{staticClass:"d-flex align-items-center",staticStyle:{"height":"34px","font-size":"0.9em","font-weight":"600"}},[_vm._v("Select folder:")]),_c('VSelect',{staticStyle:{"position":"relative"},attrs:{"options":_vm.getAllFolders.data,"value":_vm.selected,"placeholder":'Not chosen'},on:{"input":function($event){_vm.selected = $event}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_c('p',[_c('span',[_vm._v(_vm._s(selected.name))])])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('p',[_c('span',[_vm._v(_vm._s(option.name))])])]}}],null,false,3868744781)})],1):_vm._e()])]},proxy:true}])}),(_vm.messageResult !== '')?_c('div',{staticClass:"message-result mt-3",class:{
          'success': _vm.messageResult.type === 'success',
          'error': _vm.messageResult === 'danger'
         }},[_c('div',{staticClass:"icon"},[(_vm.messageResult.type === 'danger')?_c('img',{attrs:{"src":require("../../../assets/icons/error-icon.svg"),"alt":""}}):_vm._e(),(_vm.messageResult.type === 'success')?_c('img',{attrs:{"src":require("../../../assets/icons/success-icon.svg"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"text"},[_c('p',{style:({'color' : _vm.messageResult.type === 'success' ? '#00AF3B' : '#ED0034'})},[_vm._v(_vm._s(_vm.messageResult.message))])])]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"standard-btn standard-btn-primary",attrs:{"disabled":(_vm.activeTab.name !== 'new_folder' && _vm.selected === '') || (_vm.activeTab.name === 'new_folder' && _vm.new_name.length === 0)},on:{"click":function($event){return _vm.apply()}}},[(_vm.activeTab.name !== 'new_folder')?_c('span',[_vm._v("Apply")]):_vm._e(),(_vm.activeTab.name === 'new_folder')?_c('span',[_vm._v("Create and apply")]):_vm._e()])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }