





















import Vue from 'vue'

export default Vue.extend({
  name: 'ShowStrategyRuleCard',
  components: {},
  props: {
    name: {
      type: String
    },
    description: {
      type: String
    }
  }
})
