




















































import Vue from 'vue'
import BaseLabelTag from '@/components/BaseLabelTag.vue'
import ApplyRuleFromStrategyModal from '@/components/strategies/modal/ApplyRuleFromStrategyModal.vue'
import ShowStrategyRuleCard from '../../components/strategies/StrategiesRules/ShowStrategyRuleCard.vue'
import BasePagePreloader from '../../components/BasePagePreloader.vue'
import TaskWrapper from '../../components/strategies/Rule/TaskWrapper.vue'
import Schedule from '../../components/strategies/Rule/TaskWrapper/Schedule.vue'

import { mapActions, mapGetters, mapState } from 'vuex'
export default Vue.extend({
  name: 'ShowStrategy',
  data () {
    return {
      loading: true
    }
  },
  props: {
    service: {
      required: true,
      type: String
    },
    strategyId: {
      required: true
    }
  },
  components: { BaseLabelTag, ApplyRuleFromStrategyModal, ShowStrategyRuleCard, TaskWrapper, BasePagePreloader, Schedule },
  computed: {
    ...mapState('strategy', ['strategyById']),
    ...mapGetters('adService', ['getServiceInclude'])
  },
  methods: {
    ...mapActions('strategy', ['loadStrategies', 'getStrategyDetail', 'getServerDataStrategies']),
    ...mapActions('rulesMeta', ['loadAllData', 'loadAccounts', 'loadTriggers', 'loadCustomMetrics', 'getServerData']),
    ...mapActions('rulesFolders', ['loadFolders']),
    ...mapActions('adService', ['setService'])
  },
  mounted () {
    this.setService(this.service)
    const promises = [
      this.loadCustomMetrics({ from: 'strategies' }),
      this.loadFolders({}),
      this.loadStrategies({
        'include[]': this.getServiceInclude
      }),
      this.loadAllData({ requestType: 'strategies' }),
      this.getStrategyDetail({
        id: this.strategyId,
        params: {
          'include[]': this.getServiceInclude
        }
      }).then((status) => {
        setTimeout(() => { this.loading = false }, 500)
      })]
    Promise.all(promises).catch(() => this.$router.push({ name: '404' }))
  }
})
